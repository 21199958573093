export const Data={
    first:[
        {
            "supplierId": 24,
            "name": "GDS-1",
            "tin": "1234567890",
            "idNo": "A12345678",
            "sstRegNo": "SST123456",
            "tourismTaxRegNo": "TT123456",
            "miscCode": "MISC001",
            "email": "GDS-1@gbtsd.com",
            "contactNumber": "+1234567890",
            "businessActivityDescription": "Wholesale of machinery, equipment, and supplies",
            "addressLine1": "Building 5",
            "addressLine2": "Suite 10",
            "stateId": 0,
            "countryId": 0,
            "accountStatusId": 5
        },
        {
            "supplierId": 25,
            "name": "GDS-3",
            "tin": "1234567890",
            "idNo": "A12345678",
            "sstRegNo": "SST123456",
            "tourismTaxRegNo": "TT123456",
            "miscCode": "MISC001",
            "email": "GDS-3@gbtsd.com",
            "contactNumber": "+1234567890",
            "businessActivityDescription": "Wholesale of machinery, equipment, and supplies",
            "addressLine1": "Building 5",
            "addressLine2": "Suite 10",
            "stateId": 0,
            "countryId": 0,
            "accountStatusId": 5
        },
        {
            "supplierId": 26,
            "name": "656465456",
            "tin": "65456465456",
            "idNo": "56456456456",
            "sstRegNo": "456456456456456",
            "tourismTaxRegNo": "4564564564564",
            "miscCode": "56456456456",
            "email": "65564564@64564.56",
            "contactNumber": "6345645645646",
            "businessActivityDescription": "64564564 6456 4564 564 5646 4 564 4 564 564 564 654 56456 456 456 456 464 564",
            "addressLine1": "654564",
            "addressLine2": "56456456",
            "stateId": 0,
            "countryId": 0,
            "accountStatusId": 5
        },
        {
            "supplierId": 27,
            "name": "GDS-11",
            "tin": "1234567890",
            "idNo": "A12345678",
            "sstRegNo": "SST123456",
            "tourismTaxRegNo": "TT123456",
            "miscCode": "MISC001",
            "email": "GDS-11@gbtsd.com",
            "contactNumber": "+1234567890",
            "businessActivityDescription": "Wholesale of machinery, equipment, and supplies",
            "addressLine1": "Building 5",
            "addressLine2": "Suite 10",
            "stateId": 0,
            "countryId": 0,
            "accountStatusId": 5
        },
        {
            "supplierId": 28,
            "name": "GDS-12",
            "tin": "1234567890",
            "idNo": "A12345678",
            "sstRegNo": "SST123456",
            "tourismTaxRegNo": "TT123456",
            "miscCode": "MISC001",
            "email": "GDS-12@gbtsd.com",
            "contactNumber": "+1234567890",
            "businessActivityDescription": "Wholesale of machinery, equipment, and supplies",
            "addressLine1": "Building 5",
            "addressLine2": "Suite 10",
            "stateId": 0,
            "countryId": 0,
            "accountStatusId": 5
        },
        {
            "supplierId": 29,
            "name": "GDS-13",
            "tin": "1234567890",
            "idNo": "A12345678",
            "sstRegNo": "SST123456",
            "tourismTaxRegNo": "TT123456",
            "miscCode": "MISC001",
            "email": "GDS-13@gbtsd.com",
            "contactNumber": "+1234567890",
            "businessActivityDescription": "Wholesale of machinery, equipment, and supplies",
            "addressLine1": "Building 5",
            "addressLine2": "Suite 10",
            "stateId": 0,
            "countryId": 0,
            "accountStatusId": 5
        },
        {
            "supplierId": 30,
            "name": "GDS-14",
            "tin": "1234567890",
            "idNo": "A12345678",
            "sstRegNo": "SST123456",
            "tourismTaxRegNo": "TT123456",
            "miscCode": "MISC001",
            "email": "GDS-14@gbtsd.com",
            "contactNumber": "+1234567890",
            "businessActivityDescription": "Wholesale of machinery, equipment, and supplies",
            "addressLine1": "Building 5",
            "addressLine2": "Suite 10",
            "stateId": 0,
            "countryId": 0,
            "accountStatusId": 5
        },
        {
            "supplierId": 31,
            "name": "GDS-15",
            "tin": "1234567890",
            "idNo": "A12345678",
            "sstRegNo": "SST123456",
            "tourismTaxRegNo": "TT123456",
            "miscCode": "MISC001",
            "email": "GDS-15@gbtsd.com",
            "contactNumber": "+1234567890",
            "businessActivityDescription": "Wholesale of machinery, equipment, and supplies",
            "addressLine1": "Building 5",
            "addressLine2": "Suite 10",
            "stateId": 0,
            "countryId": 0,
            "accountStatusId": 5
        },
        {
            "supplierId": 32,
            "name": "GDS-16",
            "tin": "1234567890",
            "idNo": "A12345678",
            "sstRegNo": "SST123456",
            "tourismTaxRegNo": "TT123456",
            "miscCode": "MISC001",
            "email": "GDS-16@gbtsd.com",
            "contactNumber": "+1234567890",
            "businessActivityDescription": "Wholesale of machinery, equipment, and supplies",
            "addressLine1": "Building 5",
            "addressLine2": "Suite 10",
            "stateId": 0,
            "countryId": 0,
            "accountStatusId": 5
        },
        {
            "supplierId": 33,
            "name": "GDS-17",
            "tin": "1234567890",
            "idNo": "A12345678",
            "sstRegNo": "SST123456",
            "tourismTaxRegNo": "TT123456",
            "miscCode": "MISC001",
            "email": "GDS-17@gbtsd.com",
            "contactNumber": "+1234567890",
            "businessActivityDescription": "Wholesale of machinery, equipment, and supplies",
            "addressLine1": "Building 5",
            "addressLine2": "Suite 10",
            "stateId": 0,
            "countryId": 0,
            "accountStatusId": 5
        }
    ],
    
}

import React, { useState } from 'react'
import "../App.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faHouse } from "@fortawesome/free-solid-svg-icons"
import { Link } from 'react-router-dom';
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { faPinterestP } from "@fortawesome/free-brands-svg-icons";
import {faMagnifyingGlass}from "@fortawesome/free-solid-svg-icons"
import { Accordion } from 'react-bootstrap';
import { useEffect } from 'react';
import $ from 'jquery';
import HeaderInner from '../component/HeaderInner';
import FooterInner from '../component/FooterInner';



const ServiceD = () => {
    useEffect(() => {
        // Example of jQuery code inside useEffect
        (function ($) {
            "use strict";
            const accordionItems = document.querySelectorAll(".rv-accordion-item");
        
            accordionItems.forEach((accordionItem) => {
                accordionItem.onclick = () => {
                    const openedItems = document.querySelector(".rv-accordion-item.open");
                    if (accordionItem.classList.contains("open")) {
                        openedItems.classList.remove("open")
                    } else {
                        accordionItem.classList.toggle("open");
                        if (openedItems) {
                            openedItems.classList.remove("open")
                        }
                    }
                }
            })
        })($);
      }, []);
    return (
        <div>
            {/* <!-- HEADER SECTION STARTS HERE --> */}

          <HeaderInner/>

            {/* <!-- HEADER SECTION ENDS HERE --> */}


            {/* <!-- BREADCRUMB STARTS HERE --> */}
            <div class="rv-breadcrumb pt-120 pb-120">
                <div class="container">
                    <h1 class="rv-breadcrumb__title">Service Details</h1>

                    <ul class="rv-breadcrumb__nav d-flex justify-content-center">
                    <li><Link to="/"><FontAwesomeIcon icon={faHouse} /> Home</Link></li>
                        <li class="current-page"><span class="dvdr"> &#47;</span><span>Service Details</span></li>
                    </ul>
                </div>
            </div>
            {/* <!-- BREADCRUMB ENDS HERE --> */}

            {/* <!-- SERVICE DETAILS SECTION START --> */}
            <section class="rv-service-details rv-section-spacing">
                <div class="container">
                    <div class="row rv-service-details__row g-30 justify-content-center">
                        <div class="col-lg-8">
                            <div class="rv-service-details__left">
                                <div class="rv-service-details__img">
                                    <img src={require('../assests/images/rv-4-banner-bg-1.jpg')} alt="Service Details Cover" />
                                </div>
                                <h3 class="rv-service-details__title">Branding Design</h3>
                                <p class="rv-service-details__descr">It appears that your query is still quite broad. If you're
                                    referring to information about an agency, I'd be happy to help if you provide more details
                                    or clarify the type of agency you're interested in. For example, are you looking for
                                    information about a creative agency, marketing agency, design agency, or another type of
                                    agency?</p>
                                <p class="rv-service-details__descr">It seems like your query is incomplete. If you provide more
                                    details or clarify, I'd be happy to help you with information or suggestions related to
                                    branding design. Whether you're looking for information on the importance of branding
                                    design, tips for effective branding, or specific aspects of branding design, feel free to
                                    provide more context.</p>
                                <blockquote class="rv-blog-details-descr__txt rv-service-details__blockquote blockquote">
                                    <p>
                                        Lorem ipsum dolor sit abet connecters dehiscing alit sed aliquot ex
                                        supine maximus sit ramet mi laurate untraces.
                                    </p>

                                    <h6 class="blockquote__author-name">Norman Gordon</h6>
                                </blockquote>
                                <div class="rv-service-details__cards">
                                    <div class="row g-30">
                                        <div class="col-sm-6">
                                            <div class="rv-service-details-card">
                                                <span class="rv-service-details-card__tag">Creative Design</span>
                                                <div class="rv-service-details-card__icon">
                                                    <img src={require('../assests/images/rv-service-details-icon-1.png')} alt="Icon" />
                                                </div>
                                                <p class="rv-service-details-card__descr">Please Provide More Specific Details
                                                    <span class="highlight">Creative Design</span> Questions Related To.
                                                </p>
                                                <a href="#" class=" rv-service-details-card__btn rv-3-def-btn">Read More</a>
                                            </div>
                                        </div>

                                        <div class="col-sm-6">
                                            <div class="rv-service-details-card">
                                                <span class="rv-service-details-card__tag">Marketing Plan</span>
                                                <div class="rv-service-details-card__icon">
                                                    <img src={require('../assests/images/rv-service-details-icon-2.png')} alt="Icon" />
                                                </div>
                                                <p class="rv-service-details-card__descr">Create a Timeline Implementing Your
                                                    For <span class="highlight">Marketing</span> Strategies and Tactics.</p>
                                                <a href="#" class="rv-3-def-btn rv-service-details-card__btn">Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- accordion --> */}
                                <h3 class="rv-service-details__title">Frequently Asked Questions</h3>
                                <div class="rv-accordion">
                                    <div class="rv-accordion-item open">
                                        <div class="rv-accordion-item-header">
                                            <h6 class="rv-accordion-item-title">How many hours of lessons do you need to pass?
                                            </h6>
                                            <span class="rv-accordion-item-expand-icon"></span>
                                        </div>

                                        <p class="rv-accordion-item-body">
                                            Sed interdum ipsum fermentum, condimentum erat eget, egestas est. Etiam vel justo
                                            laoreet, efficitur lectus in, tincidunt magna. Aliquam eros lorem, rhoncus quis
                                            justo id.
                                        </p>
                                    </div>

                                    <div class="rv-accordion-item">
                                        <div class="rv-accordion-item-header">
                                            <h6 class="rv-accordion-item-title">How to Handle Tailgaters on the Road?</h6>
                                            <span class="rv-accordion-item-expand-icon"></span>
                                        </div>

                                        <p class="rv-accordion-item-body">
                                            Sed interdum ipsum fermentum, condimentum erat eget, egestas est. Etiam vel justo
                                            laoreet, efficitur lectus in, tincidunt magna. Aliquam eros lorem, rhoncus quis
                                            justo id.
                                        </p>
                                    </div>

                                    <div class="rv-accordion-item">
                                        <div class="rv-accordion-item-header">
                                            <h6 class="rv-accordion-item-title">How many driving lessons do I need before test?
                                            </h6>
                                            <span class="rv-accordion-item-expand-icon"></span>
                                        </div>

                                        <p class="rv-accordion-item-body">
                                            Sed interdum ipsum fermentum, condimentum erat eget, egestas est. Etiam vel justo
                                            laoreet, efficitur lectus in, tincidunt magna. Aliquam eros lorem, rhoncus quis
                                            justo id.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-9 col-xxs-12">
                            <div class="rv-service-details-sidebar-widget">
                                <h5 class="rv-service-details-sidebar-widget__title">Search</h5>
                                <form action="#" class="rv-service-details-sidebar__search-form">
                                    <input type="search" name="search" placeholder="Search here..." />
                                    <button type="submit"><FontAwesomeIcon icon={faMagnifyingGlass} /></button>
                                </form>
                            </div>

                            <div class="rv-service-details-sidebar-widget rv-service-details-sidebar-categories">
                                <ul>
                                    <li><a href="#">Content Marketing</a></li>
                                    <li><a href="#">Search Engine Optimization</a></li>
                                    <li><a href="#">Digital marketing</a></li>
                                    <li><a href="#">Graphic Designing</a></li>
                                    <li><a href="#">SEO & Content Writing</a></li>
                                    <li><a href="#">Lead Generation</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- SERVICE DETAILS SECTION END --> */}


            {/* <!-- FOOTER SECTION START --> */}
            <FooterInner/>
            {/* <!-- FOOTER SECTION END --> */}

        </div>
    )
}

export default ServiceD
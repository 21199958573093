import React from "react";
import { Link } from "react-router-dom";
const Header =() => {
    return(
        <div>
        {/* -----Header----- */}
        <div class="rv-34-header to-be-fixed ">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="rv-31-menu rv-34-menu">
                            <div class="rv-34-logo">
                                <a href="#">

                                    <img src={require('../assests/images/Alpha1.png')} alt="logo" />
                                </a>
                            </div>

                            <div class="rv-1-header-nav__sidebar">
                                <div
                                    class="sidebar-heading d-lg-none d-flex align-items-center justify-content-between">
                                    <a href="index.html" class="logo-container ">
                                        <img src={require('../assests/images/Software/logo/rv-34-logo.png')} alt="logo" />


                                    </a>
                                    <button class=" rv-1-header-mobile-menu-btn sidebar-close-btn rv-3-def-btn">
                                        <i class="fa-regular fa-xmark"></i>
                                    </button>
                                </div>
                                <nav class="rv-31-menu-list rv-1-header__nav">
                                    <ul class="rv-31-menu-list__ul">
                                        <li>
                                            <a href="#">Home</a>
                                        </li>
                                        <li><Link to="/about">About</Link></li>
                                        <li><a href="#">Blog</a>

                                        </li>
                                        <li><a href="#">Projects</a>

                                        </li>

                                        <li><a href="#">Contact</a></li>
                                    </ul>
                                </nav>

                            </div>
                            <div class="rv-31-menu-right">
                                <button class="rv-34-btn rv-34-btn-search" id="search-34">
                                <i class="fa-solid fa-magnifying-glass me-2 "></i>
                                    <span>Search...</span>
                                </button>
                                <button class=" rv-3-def-btn rv-1-header-mobile-menu-btn d-lg-none d-inline-block"
                                        id="rv-1-header-mobile-menu-btn">
                                        <i class="fa-bars fa-regular"></i>
                                    </button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {/* -----Header--End------ */}
        
        
        </div>
    )
}
export default Header

import React from 'react'
import "../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faHouse } from "@fortawesome/free-solid-svg-icons"
import { Link } from 'react-router-dom';
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { faPinterestP } from "@fortawesome/free-brands-svg-icons";
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons"
import {faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import HeaderInner from '../component/HeaderInner';

const Eror = () => {
  return (
    <div>

      {/* <!-- HEADER SECTION STARTS HERE --> */}

      <HeaderInner/>

      {/* <!-- HEADER SECTION ENDS HERE --> */}



      {/* <!-- BREADCRUMB STARTS HERE --> */}
    <div class="rv-breadcrumb pt-120 pb-120">
        <div class="container">
            <h1 class="rv-breadcrumb__title">Error 404</h1>

            <ul class="rv-breadcrumb__nav d-flex justify-content-center">
            <li><Link to="/"><FontAwesomeIcon icon={faHouse} /> Home</Link></li>
                <li class="current-page"><span class="dvdr"> &#47;</span><span>Error 404</span></li>
            </ul>
        </div>
    </div>
    {/* <!-- BREADCRUMB ENDS HERE --> */}




    {/* <!-- ERROR SECTION START --> */}
    <div class="rv-404 rv-section-spacing text-center">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-6 col-lg-8 col-md-10">
                    <img src={require('../assests/images/404.png')} alt="404 image"/>
                    <h3 class="rv-404__title">Page Not Found</h3>
                    <p class="rv-404__sub-title">Sorry, The Page You're Looking For Doesn't Exist.</p>
                    <a href="index.html" class="rv-3-def-btn rv-404__btn mt-15">Go to Homepage <FontAwesomeIcon icon={faArrowRightLong} /></a>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- ERROR SECTION END --> */}






      {/* <!-- FOOTER SECTION START --> */}
      <footer class="rv-inner-footer">
        <div class="container">
          <div class="rv-8-footer-middle">
            <div class="row g-4 justify-content-xl-between justify-content-center">
              <div class="col-xl-3 col-lg-4 col-md-6 col-8 col-xxs-12">
                <div class="rv-1-footer__about">
                  <a href="index.html"><img src={require('../assests/images/logo-2.png')} alt="Logo" class="logo" /></a>
                  <p class="rv-1-footer__about-txt">Morbi pharetra, eros sed euismod pellentesque, nulla risus
                    lobortis.</p>
                  <div class="rv-1-socials rv-inner-socials">
                    <a href="#"><FontAwesomeIcon icon={faTwitter} /></a>
                    <a href="#"><FontAwesomeIcon icon={faFacebookF} /></a>
                    <a href="#"><FontAwesomeIcon icon={faLinkedinIn} /></a>
                    <a href="#"><FontAwesomeIcon icon={faPinterestP} /></a>
                  </div>
                </div>
              </div>

              <div class="col-xl-2 col-lg-3 col-md-3 col-6 col-xxs-12">
                <div class="rv-1-footer-widget rv-inner-footer-widget">
                  <h5 class="rv-1-footer-widget__title">Useful Links</h5>
                  <ul class="rv-8-footer-widget__links">
                    <li><a href="#">About Us</a></li>
                    <li><a href="#">Latest Work</a></li>
                    <li><a href="#">Team Member</a></li>
                    <li><a href="#">Latest Blog</a></li>
                    <li><a href="#">Contact Us</a></li>
                  </ul>
                </div>
              </div>

              <div class="col-xl-2 col-lg-3 col-md-3 col-6 col-xxs-12">
                <div class="rv-1-footer-widget rv-inner-footer-widget">
                  <h5 class="rv-1-footer-widget__title">Our Services</h5>
                  <ul class="rv-8-footer-widget__links">
                    <li><a href="#">SEO Optimization</a></li>
                    <li><a href="#">Content Marketing</a></li>
                    <li><a href="#">Data Analysis</a></li>
                    <li><a href="#">Digital Marketing</a></li>
                    <li><a href="#">Web Analytics</a></li>
                  </ul>
                </div>
              </div>

              <div class="col-xl-3 col-lg-5 col-md-6 col-8 col-xxs-12">
                <div class="rv-1-footer-nwsltr">
                  <h5 class="rv-1-footer-widget__title">Newsletter Signup</h5>
                  <form action="#"
                    class="rv-6-footer-nwsltr__form  rv-8-footer-nwsltr__form rv-inner-footer-nwsltr__form">
                    <div class="nwsltr-top">
                      <input type="email" required name="email" id="rv-8-subs-form"
                        placeholder="Enter your Email..." />
                      <button><FontAwesomeIcon icon={faArrowRightLong} /></button>
                    </div>
                    <div class="rv-6-footer-nwsltr__checkbox">
                      <input type="checkbox" required id="nwsltr-checkbox" name="checkbox" value="1" />
                      <label for="nwsltr-checkbox"> I agree to the <a href="#">Privacy Policy</a>.</label>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div class="rv-2-footer rv-8-footer-bottom">
            <div class="row gy-3 align-items-center">
              <div class="col-md-8">
                <p class="rv-2-copyright rv-1-copyright mb-0 text-center text-md-start">&copy; 2024 Codebasket
                  All Rights Reserved by site</p>
              </div>
              <div class="col-md-4 text-center text-md-end">
                <div class="rv-2-footer__nav">
                  <a href="#">Privacy Policy</a>
                  <a href="#">Term of Service</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* <!-- FOOTER SECTION END --> */}
    </div>
  )
}

export default Eror
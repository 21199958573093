
import React from 'react'
import "../App.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faHouse } from "@fortawesome/free-solid-svg-icons"
import { Link } from 'react-router-dom';
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { faPinterestP } from "@fortawesome/free-brands-svg-icons";
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons"
import {faMagnifyingGlass}from "@fortawesome/free-solid-svg-icons";
import {faLink} from "@fortawesome/free-solid-svg-icons";
import HeaderInner from '../component/HeaderInner';
import FooterInner from '../component/FooterInner';

const Projects = () => {
  return (
    <div> {/* <!-- HEADER SECTION STARTS HERE --> */}

      <HeaderInner/>

      {/* <!-- HEADER SECTION ENDS HERE --> */}



      {/* <!-- BREADCRUMB STARTS HERE --> */}
      <div class="rv-breadcrumb pt-120 pb-120">
        <div class="container">
          <h1 class="rv-breadcrumb__title">Projects</h1>

          <ul class="rv-breadcrumb__nav d-flex justify-content-center">
          <li><Link to="/"><FontAwesomeIcon icon={faHouse} /> Home</Link></li>
            <li class="current-page"><span class="dvdr"> &#47;</span><span>Projects</span></li>
          </ul>
        </div>
      </div>
      {/* <!-- BREADCRUMB ENDS HERE --> */}

      {/* <!-- PROJECTS SECTION START --> */}
      <section class="rv-9-projects pt-60 pb-60">
        <div class="container">
          <div class="rv-inner-projects" data-aos="fade-up">
            <div class="row g-xl-4 g-3 row-cols-lg-3 row-cols-sm-2 row-cols-1">
              <div class="col">
                <div class="rv-3-project rv-9-project rv-inner-project">
                  <div class="rv-3-project__img">
                    <img src={require('../assests/images/LogisticsApplication.png')} alt="Project Image" />
                    <div class="rv-3-project__actions">
                      <div class="rv-3-project__actions">
                        <button class="quick-view"><FontAwesomeIcon icon={faMagnifyingGlass} /></button>
                        <a href="project-details.html"><FontAwesomeIcon icon={faLink} /></a>
                      </div>
                    </div>
                  </div>

                  <div class="rv-9-project__txt">
                    <span class="rv-3-project__sub-title">Transportation</span>
                    <h5 class="rv-9-project__title"><a href="project-details.html">Logistics Application</a></h5>
                  </div>
                </div>
              </div>

              <div class="col">
                <div class="rv-3-project rv-9-project rv-inner-project">
                  <div class="rv-3-project__img">
                    <img src={require('../assests/images/SipenManagementApp.png')} alt="Project Image" />
                    <div class="rv-3-project__actions">
                      <div class="rv-3-project__actions">
                        <button class="quick-view"><FontAwesomeIcon icon={faMagnifyingGlass} /></button>
                        <a href="project-details.html"><FontAwesomeIcon icon={faLink} /></a>
                      </div>
                    </div>
                  </div>

                  <div class="rv-9-project__txt">
                    <span class="rv-3-project__sub-title">Management Application</span>
                    <h5 class="rv-9-project__title"><a href="project-details.html">Sipena</a></h5>
                  </div>
                </div>
              </div>

              <div class="col">
                <div class="rv-3-project rv-9-project rv-inner-project">
                  <div class="rv-3-project__img">
                    <img src={require('../assests/images/rv-6-about-img-2.jpg')} alt="Project Image" />
                    <div class="rv-3-project__actions">
                      <div class="rv-3-project__actions">
                        <button class="quick-view"><FontAwesomeIcon icon={faMagnifyingGlass} /></button>
                        <a href="project-details.html"><FontAwesomeIcon icon={faLink} /></a>
                      </div>
                    </div>
                  </div>

                  <div class="rv-9-project__txt">
                    <span class="rv-3-project__sub-title">Learning App</span>
                    <h5 class="rv-9-project__title"><a href="project-details.html">Tutor-Student App</a>
                    </h5>
                  </div>
                </div>
              </div>

              <div class="col">
                <div class="rv-3-project rv-9-project rv-inner-project">
                  <div class="rv-3-project__img">
                    <img src={require('../assests/images/rv-8-project-2.jpg')} alt="Project Image" />
                    <div class="rv-3-project__actions">
                      <div class="rv-3-project__actions">
                        <button class="quick-view"><FontAwesomeIcon icon={faMagnifyingGlass} /></button>
                        <a href="project-details.html"><FontAwesomeIcon icon={faLink} /></a>
                      </div>
                    </div>
                  </div>

                  <div class="rv-9-project__txt">
                    <span class="rv-3-project__sub-title">Wild Animals</span>
                    <h5 class="rv-9-project__title"><a href="project-details.html">Save Water From
                      Pollution</a></h5>
                  </div>
                </div>
              </div>

              <div class="col">
                <div class="rv-3-project rv-9-project rv-inner-project">
                  <div class="rv-3-project__img">
                    <img src={require('../assests/images/rv-8-project-1.jpg')} alt="Project Image" />
                    <div class="rv-3-project__actions">
                      <div class="rv-3-project__actions">
                        <button class="quick-view"><FontAwesomeIcon icon={faMagnifyingGlass} /></button>
                        <a href="project-details.html"><FontAwesomeIcon icon={faLink} /></a>
                      </div>
                    </div>
                  </div>

                  <div class="rv-9-project__txt">
                    <span class="rv-3-project__sub-title">Lawn Renovation</span>
                    <h5 class="rv-9-project__title"><a href="project-details.html">Helping Young
                      Planting</a></h5>
                  </div>
                </div>
              </div>

              <div class="col">
                <div class="rv-3-project rv-9-project rv-inner-project">
                  <div class="rv-3-project__img">
                    <img src={require('../assests/images/rv-8-project-3.jpg')} alt="Project Image" />
                    <div class="rv-3-project__actions">
                      <div class="rv-3-project__actions">
                        <button class="quick-view"><FontAwesomeIcon icon={faMagnifyingGlass} /></button>
                        <a href="project-details.html"><FontAwesomeIcon icon={faLink} /></a>
                      </div>
                    </div>
                  </div>

                  <div class="rv-9-project__txt">
                    <span class="rv-3-project__sub-title">Environment</span>
                    <h5 class="rv-9-project__title"><a href="project-details.html">Protecting Nature</a>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- PROJECTS SECTION END --> */}



      {/* <!-- CTA SECTION START --> */}
      <section class="rv-14-cta rv-inner-cta rv-section-spacing">
        <div class="container">
          <div class="row g-4 align-items-center">
            <div class="col-lg-6">
              <div class="rv-14-cta__txt">
                <h6 class="rv-1-section__sub-title rv-text-anime">Our Newsletter</h6>
                <h2 class="rv-1-section__title rv-text-anime mb-0">Become a Member For Unique Updates!</h2>
              </div>
            </div>
            <div class="col-lg-6">
              <form action="#" class="rv-6-footer-nwsltr__form rv-14-nwsltr__form">
                <div class="nwsltr-left">
                  <input type="email" name="email" placeholder="Enter your Email..." />
                  <div class="rv-6-footer-nwsltr__checkbox rv-14-nwsltr__checkbox">
                    <input type="checkbox" id="nwsltr-checkbox" name="checkbox" value="1" />
                    <label for="nwsltr-checkbox"> I agree to the <a href="#">Privacy Policy</a>.</label>
                  </div>
                </div>
                <button><FontAwesomeIcon icon={faPaperPlane} /> <span class="txt">Subscribe</span></button>
              </form>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- CTA SECTION END --> */}


      {/* <!-- FOOTER SECTION START --> */}
      <FooterInner/>
      {/* <!-- FOOTER SECTION END --> */}




    </div>
  )
}

export default Projects
import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import anime from "animejs";
const HeaderInner =()=>{
    const anime = require('animejs');
    const [isSticky, setIsSticky] = useState(false);
    const handleScroll = () => {
        if (window.scrollY > 50) {
          setIsSticky(true);
        } else {
          setIsSticky(false);
        }
      };
      useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
    return(
        <div>
   {/* <!-- HEADER SECTION STARTS HERE --> */}

   <header class="rv-1-header rv-inner-header to-be-fixed"className={isSticky ? 'header sticky' : 'header'} >
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-3 col-4 col-xxs-6">
                            <div class="rv-1-logo">
                                <a href="#">
                                    <img src={require('../assests/images/Alpha1.png')} alt="logo" class="rv-1-logo" />
                                </a>
                            </div>
                        </div>

                        {/* <!-- nav menu --> */}
                        <div class="col-md-7 order-2 order-lg-1">
                            <div class="rv-1-header-nav__sidebar">
                                <div class="sidebar-heading d-lg-none d-flex align-items-center justify-content-between">
                                    <a href="index.html" class="logo-container">
                                        <img src={require('../assests/images/logo-1.png')} alt="logo" /></a>
                                    <button
                                        class="rv-3-def-btn rv-1-header-mobile-menu-btn rv-inner-mobile-menu-btn sidebar-close-btn"><i
                                            class="fa-regular fa-xmark"></i></button>
                                </div>

                                <div class="rv-1-header__nav rv-inner-header__nav">
                                    <ul class="justify-content-end">
                                        <li>
                                            <Link to="/">Home</Link>
                                            {/* <ul class="sub-menu">
                                                <li><a href="#">Software</a></li>
                                                <li><a href="#">App landing</a></li>
                                                <li><Link to="/seo">Seo</Link></li>
                                                <li><a href="#">IT Security</a></li>
                                                <li><Link to="/">IT Service</Link></li>

                                            </ul> */}
                                        </li>
                                        <li>
                                            <Link to="/about">About</Link>
                                        </li>

                                        {/* <li>
                                            <a href="#">Pages</a>
                                            <ul class="sub-menu">
                                                <li><Link to="/services">Services</Link></li>
                                                <li><Link to="/servicedd">Service Details</Link></li>
                                                <li><Link to="/projects">Projects</Link></li>
                                                <li><Link to="/projectd">Project Details</Link></li>
                                                <li><a href="#">Team Members</a></li>
                                                <li><a href="#">Register</a></li>
                                                <li><Link to="/eror">Error 404</Link></li>
                                            </ul>
                                        </li> */}

                                        {/* <li>
                                            <a href="#">Shop</a>
                                            <ul class="sub-menu">
                                            <li><a href="#">Shop</a></li>
                                                <li><a href="#">Shop with Sidebar</a></li>
                                                <li><Link to="/productd">Product Details</Link></li>
                                            </ul>
                                        </li> */}

                                        <li>
                                            <a href="#">Blog</a>
                                            <ul class="sub-menu">
                                                <li><a href="#">Blog</a></li>
                                                <li><a href="#">Blog Details</a></li>
                                            </ul>
                                        </li>

                                        {/* <li>
                                            <Link to="/contact">Contact</Link>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-2 col-8 col-xxs-6 text-end order-1 order-lg-2">
                            <div class="d-flex justify-content-center">
                                <div class="rv-inner-header-right-btns">
                                    {/* <a href="login.html"><FontAwesomeIcon icon={faUser} />Login</a> */}
                                    <Link to="/contact" class="d-sm-inline-block d-none">let's talk</Link>
                                </div>
                                <button
                                    class="rv-1-header-mobile-menu-btn rv-3-def-btn rv-inner-mobile-menu-btn d-lg-none d-inline-block"
                                    id="rv-1-header-mobile-menu-btn"><i class="fa-regular fa-bars"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            {/* <!-- HEADER SECTION ENDS HERE --> */}






        </div>
    )
}
export default HeaderInner
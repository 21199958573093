import logo from './logo.svg';
import './App.css';
import Home from './home/Home';
import About from './about/About';
import Contact from './contact/Contact';
import Services from './services/Services';
import { Helmet } from 'react-helmet';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import ServiceD from './services_detail/ServiceD';
import Projects from './projects/Projects';
import ProjectD from './project_details/ProjectD';
import Eror from './eror_404/Eror';
import Seo from './seo/Seo';
import ProductD from './product_detail/ProductD';
// import owl from './custom_js/vendor/owl-carousel/owl.carousel.min.js';







function App() {
  return (
<>
    {/* <Helmet ><script src={`${owl}`} type='text/javascript'></script></Helmet> */}
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/services" element={<Services />} />
        <Route path="/servicedd" element={<ServiceD />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/projectd" element={<ProjectD />} />
        <Route path="/eror" element={<Eror />} />
        <Route path="/seo" element={<Seo />} />
        <Route path="/productd" element={<ProductD />} />
      </Routes>

    </Router>

    </>

  );
}

export default App;

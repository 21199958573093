export const InnerData = {
    recent_project: [
        {
            id: 0,
            title: "Development",
            heading: "Improve IT Security",
            images: (require('../assests/images/Software/Portfolio/rv-34-portfolio-img1.jpg')),

        },
    
    ],
    testimonial: [
        {
            id: 0,
            name: "Dylan Meringue",
            subtitle: "Happy Customer",
            comments: "“Morbi consectetur elementum purus mattis cursus purus metus iaculis sagittis.Vestibulum molestie bibendum turpis luctus sem lacinia Quisque amet velit amet dui hendrerit ultricies id ipsum Mauris sit amet Suspends potent quis commodo ligula vestibulu lacinia est”",

        },
        {
            id: 0,
            name: "Dylan Meringue",
            subtitle: "Happy Customer",
            comments: "“Morbi consectetur elementum purus mattis cursus purus metus iaculis sagittis.Vestibulum molestie bibendum turpis luctus sem lacinia Quisque amet velit amet dui hendrerit ultricies id ipsum Mauris sit amet Suspends potent quis commodo ligula vestibulu lacinia est”",

        },
        {
            id: 0,
            name: "Dylan Meringue",
            subtitle: "Happy Customer",
            comments: "“Morbi consectetur elementum purus mattis cursus purus metus iaculis sagittis.Vestibulum molestie bibendum turpis luctus sem lacinia Quisque amet velit amet dui hendrerit ultricies id ipsum Mauris sit amet Suspends potent quis commodo ligula vestibulu lacinia est”",

        },
    
    ],
    team_member:
    [
        {
            id : 0,
            name:"Ravi O'Leigh",
            title:"CHEF TECHNOLOGY",
            image:(require('../assests/images/Software/team/rv-34-team1.jpg')),
        },
    ],
    blog:[
        {
            id: 0,
            heading:"Foremost 14 Tech Stacks in Software Building.",
            date:"March 16, 2023",
            comment:"2 Comments",
            title:"Development",
            images:(require('../assests/images/Software/blog/rv-34-blog1.jpg')),
        },
        {
            id: 0,
            heading:"Foremost 14 Tech Stacks in Software Building.",
            date:"March 16, 2023",
            comment:"2 Comments",
            title:"Meeting",
            images:(require('../assests/images/Software/blog/rv-34-blog1.jpg')),
        },
        {
            id: 0,
            heading:"Foremost 14 Tech Stacks in Software Building.",
            date:"March 16, 2023",
            comment:"2 Comments",
            title:"Optimizing",
            images:(require('../assests/images/Software/blog/rv-34-blog1.jpg')),
        },
        {
            id: 0,
            heading:"Foremost 14 Tech Stacks in Software Building.",
            date:"March 16, 2023",
            comment:"2 Comments",
            title:"Meeting",
            images:(require('../assests/images/Software/blog/rv-34-blog1.jpg')),
        },
        {
            id: 0,
            heading:"Foremost 14 Tech Stacks in Software Building.",
            date:"March 16, 2023",
            comment:"2 Comments",
            title:"Optimizing",
            images:(require('../assests/images/Software/blog/rv-34-blog1.jpg')),
        },
        {
            id: 0,
            heading:"Foremost 14 Tech Stacks in Software Building.",
            date:"March 16, 2023",
            comment:"2 Comments",
            title:"Development",
            images:(require('../assests/images/Software/blog/rv-34-blog1.jpg')),
        },
    ],
    about_Speakers:[
        {
            id:0,
            title:"Lead Speaker",
            name:"Thomas R. Toe",
            video:(require('../assests/images/speaker-1.mp4'))
        },
        {
            id:1,
            title:"Lead Speaker",
            name:"Thomas R. Toe",
            video:(require('../assests/images/speaker-2.mp4'))
        },
        {
            id:2,
            title:"Lead Speaker",
            name:"Thomas R. Toe",
            video:(require('../assests/images/speaker-3.mp4'))
        },
        {
            id:3,
            title:"Lead Speaker",
            name:"Thomas R. Toe",
            video:(require('../assests/images/speaker-4.mp4'))
        },
        {
            id:4,
            title:"Lead Speaker",
            name:"Thomas R. Toe",
            video:(require('../assests/images/speaker-2.mp4'))
        },
    ]
}
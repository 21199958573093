
import React from 'react'
import "../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faHouse } from "@fortawesome/free-solid-svg-icons"
import { Link } from 'react-router-dom';
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { faPinterestP } from "@fortawesome/free-brands-svg-icons";
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons"
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import { faCircleNodes } from "@fortawesome/free-solid-svg-icons";
import {faCalendarDays} from "@fortawesome/free-regular-svg-icons";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons"
import HeaderInner from '../component/HeaderInner';
import FooterInner from '../component/FooterInner';

const Seo = () => {
    return (
        <div>
            {/* <!-- HEADER SECTION STARTS HERE --> */}

            <HeaderInner/>

            {/* <!-- HEADER SECTION ENDS HERE --> */}



            {/* <!-- banner section start --> */}

            <section class="rv-32-banner">
                <div class="container-fluid ">
                    <div class="row">
                        <div class="col-lg-7">
                            <div class="rv-32-banner-content ">
                                <h1 class="rv-32-banner-content__title p-rel">
                                    Elevate Business <br /> Utilizing Search Engine Optimization.
                                    <img src={require('../assests/images/Software/banner/rv-32-shape.png')} alt="shape" />
                                </h1>

                                <form action="#" class="wow fadeInUp">
                                    <i class="fa-sharp fa-regular fa-magnifying-glass"></i>
                                    <input type="text" required class="w-100 " placeholder="Enter domain, keyword or Urls" />
                                    <button class="rv-32-banner-content-search">Search</button>
                                </form>
                                <div class="rv-32-banner-content-btns">
                                    Populars:
                                    <a href="https://www.google.com/" class="rv-32-banner-tag ms-3 wow fadeInUp">
                                        <img src={require('../assests/images/Software/banner/rv-32-icon1.png')} class="me-2 "
                                            alt="google" />Google.com
                                    </a>
                                    <a href="https://www.facebook.com/" class="rv-32-banner-tag wow fadeInUp"
                                        data-wow-delay="0.25s">
                                        <img src={require('../assests/images/Software/banner/rv-32-icon2.png')} class="me-2 "
                                            alt="facebook" />Facebook.com
                                    </a>
                                    <a href="https://www.twitter.com/" class="rv-32-banner-tag wow fadeInUp"
                                        data-wow-delay="0.4s">
                                        <img src={require('../assests/images/Software/banner/rv-32-icon3.png')} class="me-2 "
                                            alt="twitter" />Twitter.com
                                    </a>
                                </div>

                            </div>
                        </div>
                        <div class="col-lg-5">
                            <div class="rv-32-banner-img">
                                <img src={require('../assests/images/Software/banner/rv-32-img.png')} alt="banner-ser" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- Banner section end --> */}






            {/* <!-- Service section start --> */}

            <section class="rv-32-service mt-130">
                <div class="container">
                    <div class="row">
                        <div class="rv-32-section-heading text-center mb-45 wow fadeInUp">
                            <h2 class="rv-32-sec-title p-rel">
                                Expertise Lies in SEO & <br />
                                Marketing Services.
                                <img src={require('../assests/images/Software/banner/rv-32-shape.png')} alt="vector" />
                            </h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="rv-32-service-item wow fadeInUp">
                                <div class="rv-32-service-item__icon">
                                    <img src={require('../assests/images/Software/service/rv-32-icon1.png')} alt="icon" />
                                </div>
                                <a href="service-details.html">
                                    <h5 class="rv-32-service-item__title">Reporting Dashboard</h5>
                                </a>
                                <p class="rv-32-service-item__text">
                                    Suspendisse fermentu Suspendisse hendrerit est lectus vulputate tempus nulla
                                    ullamcorper quis.
                                </p>
                                <a href="service-details.html" class="rv-32-banner-content-search rv-32-service-btn">Learn
                                    More</a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="rv-32-service-item wow fadeInUp" data-wow-delay="0.2s">
                                <div class="rv-32-service-item__icon">
                                    <img src={require('../assests/images/Software/service/rv-32-icon2.png')} alt="icon" />
                                </div>
                                <a href="service-details.html">
                                    <h5 class="rv-32-service-item__title">Complete Analysis</h5>
                                </a>
                                <p class="rv-32-service-item__text">
                                    Suspendisse fermentu Suspendisse hendrerit est lectus vulputate tempus nulla
                                    ullamcorper quis.
                                </p>
                                <a href="service-details.html" class="rv-32-banner-content-search rv-32-service-btn">Learn
                                    More</a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="rv-32-service-item wow fadeInUp" data-wow-delay="0.3s">
                                <div class="rv-32-service-item__icon">
                                    <img src={require('../assests/images/Software/service/rv-32-icon3.png')} alt="icon" />
                                </div>
                                <a href="service-details.html">
                                    <h5 class="rv-32-service-item__title">Monthly Updates</h5>
                                </a>
                                <p class="rv-32-service-item__text">
                                    Suspendisse fermentu Suspendisse hendrerit est lectus vulputate tempus nulla
                                    ullamcorper quis.
                                </p>
                                <a href="service-details.html" class="rv-32-banner-content-search rv-32-service-btn">Learn
                                    More</a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="rv-32-service-item wow fadeInUp" data-wow-delay="0.4s">
                                <div class="rv-32-service-item__icon">
                                    <img src={require('../assests/images/Software/service/rv-32-icon4.png')} alt="icon" />
                                </div>
                                <a href="service-details.html">
                                    <h5 class="rv-32-service-item__title">Trusted Partner</h5>
                                </a>
                                <p class="rv-32-service-item__text">
                                    Suspendisse fermentu Suspendisse hendrerit est lectus vulputate tempus nulla
                                    ullamcorper quis.
                                </p>
                                <a href="service-details.html" class="rv-32-banner-content-search rv-32-service-btn">Learn
                                    More</a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="rv-32-service-item wow fadeInUp" data-wow-delay="0.5s">
                                <div class="rv-32-service-item__icon">
                                    <img src={require('../assests/images/Software/service/rv-32-icon5.png')} alt="icon" />
                                </div>
                                <a href="service-details.html">
                                    <h5 class="rv-32-service-item__title">Technical Audit</h5>
                                </a>
                                <p class="rv-32-service-item__text">
                                    Suspendisse fermentu Suspendisse hendrerit est lectus vulputate tempus nulla
                                    ullamcorper quis.
                                </p>
                                <a href="service-details.html" class="rv-32-banner-content-search rv-32-service-btn">Learn
                                    More</a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="rv-32-service-item wow fadeInUp" data-wow-delay="0.6s">
                                <div class="rv-32-service-item__icon">
                                    <img src={require('../assests/images/Software/service/rv-32-icon6.png')} alt="icon" />
                                </div>
                                <a href="service-details.html">
                                    <h5 class="rv-32-service-item__title">Keyword Research</h5>
                                </a>
                                <p class="rv-32-service-item__text">
                                    Suspendisse fermentu Suspendisse hendrerit est lectus vulputate tempus nulla
                                    ullamcorper quis.
                                </p>
                                <a href="service-details.html" class="rv-32-banner-content-search rv-32-service-btn">Learn
                                    More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- Service section end  --> */}



            {/* <!-- About section start  --> */}

            <section class="rv-32-about mt-110 pt-130 pb-135">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="rv-32-about-contant pr-80">
                                <div class="rv-32-section-heading text-center mb-50 wow fadeInUp">
                                    <h2 class="rv-32-sec-title p-rel rv-32-about-vector">
                                        Fostering a Superior Marketing Framework.
                                        <img src={require('../assests/images/Software/banner/rv-32-shape.png')} alt="vector" />
                                    </h2>
                                </div>
                                <div class="rv-32-about-item-container">
                                    <div class="rv-32-about-item wow fadeInUp">
                                        <h6 class="rv-32-about-item__title">Search Engine Optimization</h6>
                                        <a href="projects.html" class="rv-32-about-item__btn">
                                            <i class="fa-solid fa-arrow-right"></i>
                                        </a>
                                    </div>
                                    <div class="rv-32-about-item wow fadeInUp" data-wow-delay="0.25s">
                                        <h6 class="rv-32-about-item__title">Paid Search Marketing</h6>
                                        <a href="projects.html" class="rv-32-about-item__btn">
                                            <i class="fa-solid fa-arrow-right"></i>
                                        </a>
                                    </div>
                                    <div class="rv-32-about-item wow fadeInUp" data-wow-delay="0.4s">
                                        <h6 class="rv-32-about-item__title">Conversion Rate Optimization</h6>
                                        <a href="projects.html" class="rv-32-about-item__btn">
                                            <i class="fa-solid fa-arrow-right"></i>
                                        </a>
                                    </div>
                                    <div class="rv-32-about-item wow fadeInUp" data-wow-delay="0.5s">
                                        <h6 class="rv-32-about-item__title">Social Media Marketing</h6>
                                        <a href="projects.html" class="rv-32-about-item__btn">
                                            <i class="fa-solid fa-arrow-right"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="rv-32-about-img">
                                <img src={require('../assests/images/Software/about/rv-32-abt-1.png')} class="wow slideInRight"
                                    alt="about img" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- About  section end  --> */}




            {/* <!-- Team section start  --> */}

            <section class="rv-32-team pt-130">
                <div class="container">
                    <div class="row">
                        <div class="rv-32-section-heading text-center mb-45 wow fadeInUp">
                            <h2 class="rv-32-sec-title p-rel rv-32-team-vector">
                                Meet The Creative Talents <br />
                                That Drive Projects.
                                <img src={require('../assests/images/Software/banner/rv-32-shape.png')} class="rv-32-team__vector"
                                    alt="vector" />
                            </h2>
                        </div>
                    </div>
                    <div class=" rv-32-team-slider swiper-container rv-inner-team-row">
                        <div class="swiper-wrapper row-cols-lg-3 row-cols-2 row-cols-xxs-1 g-30">
                            <div class="swiper-slide">
                                <div class="rv-9-member rv-inner-member">
                                    <div class="rv-9-member__img">
                                        <img src={require('../assests/images/Software/team/rv-32-team1.jpg')} alt="Project Image" />
                                    </div>

                                    <div class="rv-9-member__txt ms-0 ">
                                        <div class="rv-9-member-socials rv-inner-member-socials">
                                            <div class="rv-1-speaker__socials rv-32-team-member">
                                                <a href="#"><FontAwesomeIcon icon={faFacebookF} /></a>
                                                <a href="#"><FontAwesomeIcon icon={faTwitter} /></a>
                                                <a href="#"><FontAwesomeIcon icon={faLinkedinIn} /></a>
                                            </div>
                                            <div class="rv-9-member-socials__icon"><FontAwesomeIcon icon={faCircleNodes} /></div>
                                        </div>
                                        <h5 class="rv-32-team__title"><a href="#">Hugh Millie-Yate</a></h5>
                                        <span class="rv-3-project__sub-title">Founder and CEO</span>
                                    </div>
                                </div>
                            </div>

                            <div class="swiper-slide">
                                <div class="rv-9-member rv-inner-member">
                                    <div class="rv-9-member__img">
                                        <img src={require('../assests/images/Software/team/rv-32-team2.jpg')} alt="Project Image" />
                                    </div>

                                    <div class="rv-9-member__txt ms-0 ">
                                        <div class="rv-9-member-socials rv-inner-member-socials">
                                            <div class="rv-1-speaker__socials rv-32-team-member">
                                                <a href="#"><FontAwesomeIcon icon={faFacebookF} /></a>
                                                <a href="#"><FontAwesomeIcon icon={faTwitter} /></a>
                                                <a href="#"><FontAwesomeIcon icon={faLinkedinIn} /></a>
                                            </div>
                                            <div class="rv-9-member-socials__icon"><FontAwesomeIcon icon={faCircleNodes} /></div>
                                        </div>
                                        <h5 class="rv-32-team__title"><a href="#">Druid Wensleydale</a></h5>
                                        <span class="rv-3-project__sub-title">Financial Expert</span>
                                    </div>
                                </div>
                            </div>

                            <div class="swiper-slide">
                                <div class="rv-9-member rv-inner-member">
                                    <div class="rv-9-member__img">
                                        <img src={require('../assests/images/Software/team/rv-32-team3.jpg')} alt="Project Image" />
                                    </div>

                                    <div class="rv-9-member__txt ms-0 ">
                                        <div class="rv-9-member-socials rv-inner-member-socials">
                                            <div class="rv-1-speaker__socials rv-32-team-member">
                                                <a href="#"><FontAwesomeIcon icon={faFacebookF} /></a>
                                                <a href="#"><FontAwesomeIcon icon={faTwitter} /></a>
                                                <a href="#"><FontAwesomeIcon icon={faLinkedinIn} /></a>
                                            </div>
                                            <div class="rv-9-member-socials__icon"><FontAwesomeIcon icon={faCircleNodes} /></div>
                                        </div>
                                        <h5 class="rv-32-team__title"><a href="#">Fergus Douchebag</a></h5>
                                        <span class="rv-3-project__sub-title">Support Manager</span>
                                    </div>
                                </div>
                            </div>

                            <div class="swiper-slide">
                                <div class="rv-9-member rv-inner-member">
                                    <div class="rv-9-member__img">
                                        <img src="assets/img/Software/team/rv-32-team2.jpg" alt="Project Image" />
                                    </div>

                                    <div class="rv-9-member__txt ms-0 ">
                                        <div class="rv-9-member-socials rv-inner-member-socials">
                                            <div class="rv-1-speaker__socials rv-32-team-member">
                                                <a href="#"><FontAwesomeIcon icon={faFacebookF} /></a>
                                                <a href="#"><FontAwesomeIcon icon={faTwitter} /></a>
                                                <a href="#"><FontAwesomeIcon icon={faLinkedinIn} /></a>
                                            </div>
                                            <div class="rv-9-member-socials__icon"><FontAwesomeIcon icon={faCircleNodes} /></div>
                                        </div>
                                        <h5 class="rv-32-team__title"><a href="#">Druid Wensleydale</a></h5>
                                        <span class="rv-3-project__sub-title">Financial Expert</span>
                                    </div>
                                </div>
                            </div>

                            <div class="swiper-slide">
                                <div class="rv-9-member rv-inner-member">
                                    <div class="rv-9-member__img">
                                        <img src={require('../assests/images/Software/team/rv-32-team3.jpg')} alt="Project Image" />
                                    </div>

                                    <div class="rv-9-member__txt ms-0 ">
                                        <div class="rv-9-member-socials rv-inner-member-socials">
                                            <div class="rv-1-speaker__socials rv-32-team-member">
                                                <a href="#"><FontAwesomeIcon icon={faFacebookF} /></a>
                                                <a href="#"><FontAwesomeIcon icon={faTwitter} /></a>
                                                <a href="#"><FontAwesomeIcon icon={faLinkedinIn} /></a>
                                            </div>
                                            <div class="rv-9-member-socials__icon"><FontAwesomeIcon icon={faCircleNodes} /></div>
                                        </div>
                                        <h5 class="rv-32-team__title"><a href="#">Fergus Douchebag</a></h5>
                                        <span class="rv-3-project__sub-title">Support Manager</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="rv-32-team-controller mt-60"></div>
                    </div>
                </div>
            </section>

            {/* <!-- Team section end  --> */}



            {/* <!-- Gallary section start  --> */}

<section class="rv-32-gallary pb-140 mt-100">
    <div class="container">
        <div class="row">
            <div class="rv-32-section-heading  mb-50 wow fadeInUp">
                <h2 class="rv-32-sec-title p-rel">
                    The SEO Projects Have <br/>
                    Now Reached Completion.
                    <img src={require('../assests/images/Software/banner/rv-32-shape.png')} alt="vector"/>
                </h2>
            </div>
        </div>
        <div class="row">
            <div class="rv-32-gallary-container">
                <div class="rv-32-gallary-img rv-32-gallary-big-img">
                    <img src={require('../assests/images/Software/gallary/rv-32-big-img.jpg')} alt="gallary"/>
                    <div class="rv-gallary-img-ovarlay">
                        <div class="rv-32-gallary-img-text">
                            <a href="projects.html" class="rv-32-gallary-keyword">Keyword Research</a>
                            <a href="project-details.html">
                                <h6 class="rv-32-gallary-img-title">Partnership SEO Consulting</h6>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="rv-32-gallary-img">
                    <img src={require('../assests/images/Software/gallary/rv-32-img2.jpg')} alt="gallary"/>
                    <div class="rv-gallary-img-ovarlay">
                        <div class="rv-32-gallary-img-text">
                            <a href="projects.html" class="rv-32-gallary-keyword">Keyword Research</a>
                            <a href="project-details.html">
                                <h6 class="rv-32-gallary-img-title">Partnership SEO Consulting</h6>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="rv-32-gallary-img">
                    <img src={require('../assests/images/Software/gallary/rv-32-img3.jpg')} alt="gallary"/>
                    <div class="rv-gallary-img-ovarlay">
                        <div class="rv-32-gallary-img-text">
                            <a href="projects.html" class="rv-32-gallary-keyword">Keyword Research</a>
                            <a href="project-details.html">
                                <h6 class="rv-32-gallary-img-title">Partnership SEO Consulting</h6>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="rv-32-gallary-img">
                    <img src={require('../assests/images/Software/gallary/rv-32-img4.jpg')} alt="gallary"/>
                    <div class="rv-gallary-img-ovarlay">
                        <div class="rv-32-gallary-img-text">
                            <a href="projects.html" class="rv-32-gallary-keyword">Keyword Research</a>
                            <a href="project-details.html">
                                <h6 class="rv-32-gallary-img-title">Partnership SEO Consulting</h6>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="rv-32-gallary-img">
                    <img src={require('../assests/images/Software/gallary/rv-32-img5.jpg')} alt="gallary"/>
                    <div class="rv-gallary-img-ovarlay">
                        <div class="rv-32-gallary-img-text">
                            <a href="projects.html" class="rv-32-gallary-keyword">Keyword Research</a>
                            <a href="project-details.html">
                                <h6 class="rv-32-gallary-img-title">Partnership SEO Consulting</h6>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

{/* <!-- Gallary section end  --> */}


{/* <!-- Testimonial section start  --> */}

<section class="rv-32-testimonial pt-130 pb-140">
    <div class="container">
        <div class="row">
            <div
                class="rv-32-section-heading d-flex justify-content-md-between justify-content-center align-items-center  mb-50 wow fadeInUp">
                <h2 class="rv-32-sec-title p-rel">
                    Endorsed by Professionals<br/>
                    On Global Scale.
                    <img src={require('../assests/images/Software/banner/rv-32-shape.png')} alt="vector"/>
                </h2>
                <div class="rv-32-btns">
                    <button class="rv-32-about-item__btn rv-32-testimonial-btn-left">
                        <i class="fa-solid fa-arrow-left"></i>
                    </button>
                    <button class="rv-32-about-item__btn rv-32-testimonial-btn-right">
                        <i class="fa-solid fa-arrow-right"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="rv-32-testimonial-slider">
        <div class="rv-32-testimonial-swiper-container swiper-container">
            <div class="swiper-wrapper">
                <div class="swiper-slide">
                    <div class="rv-29-testimonial-item">
                        <div class="rv-29-testimonial-item__stars">
                            <span><img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star"/></span>
                            <span><img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star"/></span>
                            <span><img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star"/></span>
                            <span><img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star"/></span>
                            <span><img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star"/></span>
                        </div>
                        <p class="rv-29-testimonial-item__text">
                            Morbi nec accusant diam Maecenas gravida masa Admetus laces leafed, at rostrum talus
                            condiment arco et vitae
                            magna
                            maximite meatus torpids pharetra.

                        </p>
                        <div class="rv-29-testimonial-item__author">
                            <img src={require('../assests/images/rv-29-testimonial-img1.jpg')} alt="lee mark"/>
                            <span> Lance Bagrol</span>
                        </div>
                    </div>
                </div>
                <div class="swiper-slide">
                    <div class="rv-29-testimonial-item">
                        <div class="rv-29-testimonial-item__stars">
                            <span><img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star"/></span>
                            <span><img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star"/></span>
                            <span><img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star"/></span>
                            <span><img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star"/></span>
                            <span><img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star"/></span>
                        </div>
                        <p class="rv-29-testimonial-item__text">
                            Morbi nec accusant diam Maecenas gravida masa Admetus laces leafed, at rostrum talus
                            condiment arco et vitae
                            magna
                            maximite meatus torpids pharetra.

                        </p>
                        <div class="rv-29-testimonial-item__author">
                            <img src={require('../assests/images/rv-29-testimonial-img2.jpg')} alt="lee mark"/>
                            <span> Hanson Dick</span>
                        </div>
                    </div>
                </div>
                <div class="swiper-slide">
                    <div class="rv-29-testimonial-item">
                        <div class="rv-29-testimonial-item__stars">
                            <span><img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star"/></span>
                            <span><img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star"/></span>
                            <span><img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star"/></span>
                            <span><img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star"/></span>
                            <span><img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star"/></span>
                        </div>
                        <p class="rv-29-testimonial-item__text">
                            Morbi nec accusant diam Maecenas gravida masa Admetus laces leafed, at rostrum talus
                            condiment arco et vitae
                            magna
                            maximite meatus torpids pharetra.

                        </p>
                        <div class="rv-29-testimonial-item__author">
                            <img src={require('../assests/images/rv-29-testimonial-img3.jpg')} alt="lee mark"/>
                            <span> Miles Tone</span>
                        </div>
                    </div>
                </div>
                <div class="swiper-slide">
                    <div class="rv-29-testimonial-item">
                        <div class="rv-29-testimonial-item__stars">
                            <span><img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star"/></span>
                            <span><img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star"/></span>
                            <span><img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star"/></span>
                            <span><img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star"/></span>
                            <span><img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star"/></span>
                        </div>
                        <p class="rv-29-testimonial-item__text">
                            Morbi nec accusant diam Maecenas gravida masa Admetus laces leafed, at rostrum talus
                            condiment arco et vitae
                            magna
                            maximite meatus torpids pharetra.

                        </p>
                        <div class="rv-29-testimonial-item__author">
                            <img src={require('../assests/images/rv-29-testimonial-img1.jpg')} alt="lee mark"/>
                            <span> Lance Bagrol</span>
                        </div>
                    </div>
                </div>
                <div class="swiper-slide">
                    <div class="rv-29-testimonial-item">
                        <div class="rv-29-testimonial-item__stars">
                            <span><img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star"/></span>
                            <span><img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star"/></span>
                            <span><img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star"/></span>
                            <span><img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star"/></span>
                            <span><img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star"/></span>
                        </div>
                        <p class="rv-29-testimonial-item__text">
                            Morbi nec accusant diam Maecenas gravida masa Admetus laces leafed, at rostrum talus
                            condiment arco et vitae
                            magna
                            maximite meatus torpids pharetra.

                        </p>
                        <div class="rv-29-testimonial-item__author">
                            <img src={require('../assests/images/rv-29-testimonial-img2.jpg')} alt="lee mark"/>
                            <span> Hanson Dick</span>
                        </div>
                    </div>
                </div>
                <div class="swiper-slide">
                    <div class="rv-29-testimonial-item">
                        <div class="rv-29-testimonial-item__stars">
                            <span><img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star"/></span>
                            <span><img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star"/></span>
                            <span><img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star"/></span>
                            <span><img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star"/></span>
                            <span><img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star"/></span>
                        </div>
                        <p class="rv-29-testimonial-item__text">
                            Morbi nec accusant diam Maecenas gravida masa Admetus laces leafed, at rostrum talus
                            condiment arco et vitae
                            magna
                            maximite meatus torpids pharetra.

                        </p>
                        <div class="rv-29-testimonial-item__author">
                            <img src={require('../assests/images/rv-29-testimonial-img3.jpg')} alt="lee mark"/>
                            <span> Miles Tone</span>
                        </div>
                    </div>
                </div>
                <div class="swiper-slide">
                    <div class="rv-29-testimonial-item">
                        <div class="rv-29-testimonial-item__stars">
                            <span><img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star"/></span>
                            <span><img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star"/></span>
                            <span><img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star"/></span>
                            <span><img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star"/></span>
                            <span><img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star"/></span>
                        </div>
                        <p class="rv-29-testimonial-item__text">
                            Morbi nec accusant diam Maecenas gravida masa Admetus laces leafed, at rostrum talus
                            condiment arco et vitae
                            magna
                            maximite meatus torpids pharetra.

                        </p>
                        <div class="rv-29-testimonial-item__author">
                            <img src={require('../assests/images/rv-29-testimonial-img2.jpg')} alt="lee mark"/>
                            <span> Hanson Dick</span>
                        </div>
                    </div>
                </div>
                <div class="swiper-slide">
                    <div class="rv-29-testimonial-item">
                        <div class="rv-29-testimonial-item__stars">
                            <span><img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star"/></span>
                            <span><img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star"/></span>
                            <span><img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star"/></span>
                            <span><img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star"/></span>
                            <span><img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star"/></span>
                        </div>
                        <p class="rv-29-testimonial-item__text">
                            Morbi nec accusant diam Maecenas gravida masa Admetus laces leafed, at rostrum talus
                            condiment arco et vitae
                            magna
                            maximite meatus torpids pharetra.

                        </p>
                        <div class="rv-29-testimonial-item__author">
                            <img src={require('../assests/images/rv-29-testimonial-img3.jpg')} alt="lee mark"/>
                            <span> Miles Tone</span>
                        </div>
                    </div>
                </div>
                <div class="swiper-slide">
                    <div class="rv-29-testimonial-item">
                        <div class="rv-29-testimonial-item__stars">
                            <span><img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star"/></span>
                            <span><img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star"/></span>
                            <span><img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star"/></span>
                            <span><img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star"/></span>
                            <span><img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star"/></span>
                        </div>
                        <p class="rv-29-testimonial-item__text">
                            Morbi nec accusant diam Maecenas gravida masa Admetus laces leafed, at rostrum talus
                            condiment arco et vitae
                            magna
                            maximite meatus torpids pharetra.

                        </p>
                        <div class="rv-29-testimonial-item__author">
                            <img src={require('../assests/images/rv-29-testimonial-img1.jpg')} alt="lee mark"/>
                            <span> Lance Bagrol</span>
                        </div>
                    </div>
                </div>
                <div class="swiper-slide">
                    <div class="rv-29-testimonial-item">
                        <div class="rv-29-testimonial-item__stars">
                            <span><img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star"/></span>
                            <span><img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star"/></span>
                            <span><img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star"/></span>
                            <span><img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star"/></span>
                            <span><img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star"/></span>
                        </div>
                        <p class="rv-29-testimonial-item__text">
                            Morbi nec accusant diam Maecenas gravida masa Admetus laces leafed, at rostrum talus
                            condiment arco et vitae
                            magna
                            maximite meatus torpids pharetra.

                        </p>
                        <div class="rv-29-testimonial-item__author">
                            <img src={require('../assests/images/rv-29-testimonial-img2.jpg')} alt="lee mark"/>
                            <span> Hanson Dick</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="rv-29-testimonial-rating wow fadeInUp">
            <span>5.0</span>
            <span>Out of </span>
            <span>5.0</span>
            <span>based On</span>
            <a href="#">1763 reviews</a>
        </div>
    </div>
</section>

{/* <!-- Testimonial section end  --> */}



{/* <!-- Blog section start  --> */}

<section class="rv-32-blog mt-130 mb-5">
    <div class="container">
        <div class="row">
            <div class="rv-32-section-heading text-center mb-50 wow fadeInUp">
                <h2 class="rv-32-sec-title ">
                    Latest Blog and News
                </h2>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="rv-31-blog-item rv-32-blog-item wow fadeInUp" data-wow-delay="0.25s">
                    <div class="rv-31-blog-item__img">
                        <a href="blog-details.html">
                            <img src={require('../assests/images/Software/blog/rv-32-blog-1.jpg')} alt="blog"/>
                        </a>
                    </div>
                    <div class="rv-31-blog-item__content">
                        <span class="rv-31-blog-item__content-app">Marketing</span>
                        <span class="rv-31-blog-item__content-date">
                            <FontAwesomeIcon icon={faCalendarDays} />
                              July 24, 2023</span>
                        <a href="blog-details.html" class="d-block ">
                            <h5 class="rv-31-blog-item__content-title">
                                SEO Mind-State Campaigns Gain Traction
                            </h5>
                        </a>
                        <a href="blog-details.html" class="rv-31-blog-item__content-readMore">Read More <FontAwesomeIcon icon={faArrowRight} />
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="rv-31-blog-item rv-32-blog-item wow fadeInUp" data-wow-delay="0.4s">
                    <div class="rv-31-blog-item__img">
                        <a href="blog-details.html">
                            <img src={require('../assests/images/Software/blog/rv-32-blog-2.jpg')} alt="blog"/>
                        </a>
                    </div>
                    <div class="rv-31-blog-item__content">
                        <span class="rv-31-blog-item__content-app">Social Media</span>
                        <span class="rv-31-blog-item__content-date"><FontAwesomeIcon icon={faCalendarDays} />
                              June 24, 2023</span>
                        <a href="blog-details.html" class="d-block ">
                            <h5 class="rv-31-blog-item__content-title">
                                Establish a Web Shop with Angular Commerce.
                            </h5>
                        </a>
                        <a href="blog-details.html" class="rv-31-blog-item__content-readMore">Read More     <FontAwesomeIcon icon={faArrowRight} />
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="rv-31-blog-item rv-32-blog-item wow fadeInUp" data-wow-delay="0.55s">
                    <div class="rv-31-blog-item__img">
                        <a href="blog-details.html">
                            <img src={require('../assests/images/Software/blog/rv-32-blog-3.jpg')} alt="blog"/>
                        </a>
                    </div>
                    <div class="rv-31-blog-item__content">
                        <span class="rv-31-blog-item__content-app">Marketing</span>
                        <span class="rv-31-blog-item__content-date"><FontAwesomeIcon icon={faCalendarDays} />   
                             March 24, 2023</span>
                        <a href="blog-details.html" class="d-block ">
                            <h5 class="rv-31-blog-item__content-title">
                                Mastering Self Discipline Finish a Task.
                            </h5>
                        </a>
                        <a href="blog-details.html" class="rv-31-blog-item__content-readMore">Read More <FontAwesomeIcon icon={faArrowRight} />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

{/* <!-- Blog section end  --> */}





{/* <!-- Footer section start  --> */}

<FooterInner/>

{/* <!-- Footer section end  --> */}





        </div>
    )
}

export default Seo

import React from 'react'
// import "./Index.css";
import "../App.css";
import { Link } from 'react-router-dom';
import "../component/Header";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { faShareNodes } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import Header from '../component/Header';
import { InnerData } from '../component/InnerData';
import FooterInner from '../component/FooterInner';
import HeaderInner from '../component/HeaderInner';



const Home = () => {



    useEffect(() => {
        // Dynamically add Swiper CSS
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = `/css/swiper-bundle.min.css`;
        document.head.appendChild(link);

        // Dynamically add Swiper JS
        const script = document.createElement('script');
        script.src = 'https://unpkg.com/swiper/swiper-bundle.min.js';
        script.async = true;
        document.body.appendChild(script);

        // Initialize Swiper once the script is loaded
        script.onload = () => {
            const Ourletest = new window.Swiper('.swiper-container2', {
                slidesPerView: 4,
                spaceBetween: 25,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },

                scrollbar: { draggable: true },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,

                },
            });
            const testimonial = new window.Swiper('.swiper-container1', {
                slidesPerView: 1,
                spaceBetween: 25,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                autoplay: true,
                loop: true,
                scrollbar: false,
                pagination: {
                    el: '.swiper-pagination',
                    type: 'fraction',
                    clickable: true,

                },
            });
            const blog = new window.Swiper('.swiper-container', {
                slidesPerView: 3,
                spaceBetween: 25,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                autoplay: true,
                loop: true,
                scrollbar: false,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
            });
        };

        // Cleanup: remove the script and link elements when the component unmounts
        return () => {
            document.body.removeChild(script);
            document.head.removeChild(link);
        };
    }, []); // Empty array ensures this effect runs only once

 
 const repeatedData = Array.from({ length: 6 }, () => InnerData.recent_project[0]);
 const teamMembers = Array.from({length: 10}, () => InnerData.team_member[0]);
    return (
        <div>



            <HeaderInner/>




            {/* -----Banner--start------ */}
            <div class="rv-34-banner">

                <img src={require('../assests/images/Software/banner/rv-34-banner.jpg')} class="w-100" />
                <div class="rv-34-banner-overlay">
                    <div class="container">
                        <div class="row">
                            <div class="rv-34-banner-content">
                                <span class="rv-34-tag text-white wow fadeInUp">Unmatched IT Expertise</span>
                                <h1 class="rv-34-banner-title">
                                    Technology Services<br></br>
                                    <span>&</span> IT Solutions.
                                </h1>
                                <p class="rv-34-banner-text">
                                    Ut a enim aliquam, vehicula sem at, luctus risus. Vestibulum ultrices molestie<br></br>
                                    auctor ornare scelerisque odio rhoncus at.
                                </p>
                                <div class="rv-34-banner-btns mt-40">
                                    <a href="about.html" class="rv-34-btn mr-15 wow fadeInUp me-3">Learn More
                                    <i class="fa-solid fa-arrow-right-long ms-1"></i>
                                    </a>
                                    <a href="contact.html" class="rv-34-btn wow fadeInUp" data-wow-delay="0.3s">Get in Touch
                                    <i class="fa-solid fa-arrow-right-long ms-1 "></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>



            {/*  Banner section end  */}

            {/* Service section start  */}


            <div class="rv-34-service mt-120 ">
                <div class="container">
                    <div class="row">
                        <div class="rv-34-section-heading text-center mb-35">
                            <span class="rv-34-tag wow fadeInUp">What We Do?</span>
                            <h2 class="rv-34-section-title wow fadeInUp" data-wow-delay="0.3s">Some Exclusive Service</h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="rv-32-service-item rv-34-service-item wow fadeInUp">
                                <div class="rv-32-service-item__icon">

                                    <img src={require('../assests/images/Software/service/rv-34-icon1.png')} alt="icon"></img>
                                </div>
                                <h5 class="rv-32-service-item__title">Web Development</h5>
                                <p class="rv-32-service-item__text">
                                    Suspendisse fermentu Suspendisse hendrerit est lectus vulputate tempus nulla
                                    ullamcorper quis.
                                </p>
                                <div class="rv-34-service-item__overlay">
                                    <a href="service-details.html">
                                        <h5 class="rv-32-service-item__title">Web Development</h5>
                                    </a>
                                    <p class="rv-32-service-item__text">
                                        Suspendisse fermentu Suspendi hendrerit est lectus vulputate tempus nulla
                                        ullamcorper quis.
                                    </p>
                                    <a href="service-details.html"
                                        class="rv-31-blog-item__content-readMore rv-34-view-link">
                                            Read More <i class="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="rv-32-service-item rv-34-service-item wow fadeInUp" data-wow-delay="0.25s">
                                <div class="rv-32-service-item__icon">
                                    <img src={require('../assests/images/Software/service/rv-34-icon2.png')} alt="icon"></img>
                                </div>
                                <h5 class="rv-32-service-item__title">Business Planning</h5>
                                <p class="rv-32-service-item__text">
                                    Suspendisse fermentu Suspendisse hendrerit est lectus vulputate tempus nulla
                                    ullamcorper quis.
                                </p>
                                <div class="rv-34-service-item__overlay">
                                    <a href="service-details.html">
                                        <h5 class="rv-32-service-item__title">Business Planning</h5>
                                    </a>
                                    <p class="rv-32-service-item__text">
                                        Suspendisse fermentu Suspendi hendrerit est lectus vulputate tempus nulla
                                        ullamcorper quis.
                                    </p>
                                    <a href="service-details.html"
                                        class="rv-31-blog-item__content-readMore rv-34-view-link">Read More <i
                                            class="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="rv-32-service-item rv-34-service-item wow fadeInUp" data-wow-delay="0.35s">
                                <div class="rv-32-service-item__icon">

                                    <img src={require('../assests/images/Software/service/rv-34-icon3.png')} alt="icon"></img>
                                </div>
                                <h5 class="rv-32-service-item__title">UI/UX Design</h5>
                                <p class="rv-32-service-item__text">
                                    Suspendisse fermentu Suspendisse hendrerit est lectus vulputate tempus nulla
                                    ullamcorper quis.
                                </p>
                                <div class="rv-34-service-item__overlay">
                                    <a href="service-details.html">
                                        <h5 class="rv-32-service-item__title">UI/UX Design</h5>
                                    </a>
                                    <p class="rv-32-service-item__text">
                                        Suspendisse fermentu Suspendi hendrerit est lectus vulputate tempus nulla
                                        ullamcorper quis.
                                    </p>
                                    <a href="service-details.html"
                                        class="rv-31-blog-item__content-readMore rv-34-view-link">Read More <i
                                            class="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="rv-32-service-item rv-34-service-item wow fadeInUp" data-wow-delay="0.45s">
                                <div class="rv-32-service-item__icon">

                                    <img src={require('../assests/images/Software/service/rv-34-icon4.png')} alt="icon"></img>
                                </div>
                                <h5 class="rv-32-service-item__title">SEO Marketing</h5>
                                <p class="rv-32-service-item__text">
                                    Suspendisse fermentu Suspendisse hendrerit est lectus vulputate tempus nulla
                                    ullamcorper quis.
                                </p>
                                <div class="rv-34-service-item__overlay">
                                    <a href="service-details.html">
                                        <h5 class="rv-32-service-item__title">SEO Marketing</h5>
                                    </a>
                                    <p class="rv-32-service-item__text">
                                        Suspendisse fermentu Suspendihendrerit est lectus vulputate tempus nulla
                                        ullamcorper quis.
                                    </p>
                                    <a href="service-details.html"
                                        class="rv-31-blog-item__content-readMore rv-34-view-link">Read More <i
                                            class="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="rv-32-service-item rv-34-service-item wow fadeInUp" data-wow-delay="0.55s">
                                <div class="rv-32-service-item__icon">

                                    <img src={require('../assests/images/Software/service/rv-34-icon5.png')} alt="icon"></img>
                                </div>
                                <h5 class="rv-32-service-item__title">Digital Marketing</h5>
                                <p class="rv-32-service-item__text">
                                    Suspendisse fermentu Suspendisse hendrerit est lectus vulputate tempus nulla
                                    ullamcorper quis.
                                </p>
                                <div class="rv-34-service-item__overlay">
                                    <a href="service-details.html">
                                        <h5 class="rv-32-service-item__title">Digital Marketing</h5>
                                    </a>
                                    <p class="rv-32-service-item__text">
                                        Suspendisse fermentu Suspendi hendrerit est lectus vulputate tempus nulla
                                        ullamcorper quis.
                                    </p>
                                    <a href="service-details.html"
                                        class="rv-31-blog-item__content-readMore rv-34-view-link">Read More <i
                                            class="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="rv-32-service-item rv-34-service-item wow fadeInUp" data-wow-delay="0.65s">
                                <div class="rv-32-service-item__icon">

                                    <img src={require('../assests/images/Software/service/rv-34-icon2.png')} alt="icon"></img>
                                </div>
                                <h5 class="rv-32-service-item__title">Optimizing System</h5>
                                <p class="rv-32-service-item__text">
                                    Suspendisse fermentu Suspendisse hendrerit est lectus vulputate tempus nulla
                                    ullamcorper quis.
                                </p>
                                <div class="rv-34-service-item__overlay">
                                    <a href="service-details.html">
                                        <h5 class="rv-32-service-item__title">Optimizing System</h5>
                                    </a>
                                    <p class="rv-32-service-item__text">
                                        Suspendisse fermentu Suspendi hendrerit est lectus vulputate tempus nulla
                                        ullamcorper quis.
                                    </p>
                                    <a href="service-details.html"
                                        class="rv-31-blog-item__content-readMore rv-34-view-link">Read More <i
                                            class="fa-solid fa-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {/* Service section start  */}

            {/* About section start   */}

            <section class="rv-34-abt">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="rv-34-abt-imgs wow slideInLeft">
                                <img src={require('../assests/images/Software/about/rv-34-abt-1.png')} class="rv-34-big-img" alt="speaker" />

                                <img src={require('../assests/images/Software/about/rv-34-abt-2.png')} class="rv-34-sm-img" alt="speaker" />
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="rv-34-abt-content">
                                <div class="rv-34-section-heading mb-35">
                                    <span class="rv-34-tag">What We Do?</span>
                                    <h2 class="rv-34-section-title">Made-To-Order IT <br /> Services For Your Firm.
                                    </h2>
                                    <p class="rv-34-abt-content__text">
                                        Fusce egestas viverra libero eu elementum. Maecenas sit amet lorem nec eros
                                        ullamcorper Vivamus finibus lacinia erat,
                                        tristique.
                                    </p>
                                    <div class="rv-34-abt-content__btns mt-40 wow fadeInUp">

                                        <a href="about.html" class="rv-34-btn mr-20">Read More
                                            <FontAwesomeIcon icon={faArrowRightLong} />
                                        </a>
                                        <a href="https://www.youtube.com/embed/PBDlbSAddAI?si=v8WM4F7YcsE4MMtS"
                                            class="rv-34-vdo-btn my-video-links" data-autoplay="true" data-vbtype="video"
                                            data-maxwidth="800px">
                                            <FontAwesomeIcon icon={faPlay} />
                                        </a>
                                        <span>How it Works</span>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* About  section end   */}

            {/* <!-- Portfolio section start  --> */}

            <section class="rv-34-gallary">
                <div class="container">
                    <div class="row">
                        <div
                            class="rv-34-section-heading d-flex justify-content-md-between justify-content-center align-items-center mb-35">
                            <div class="rv-34-section-heading-left">
                                <span class="rv-34-tag wow fadeInUp">Recent Projects</span>
                                <h2 class="rv-34-section-title wow fadeInUp" data-wow-delay="0.3s">Our Latest Case Studies
                                </h2>
                            </div>
                            <div class="rv-34-porfolio-slider-controller"></div>
                        </div>
                    </div>
                </div>
                <div class="rv-34-portfolio-slider swiper-container2">
                    <div class="swiper-wrapper">
                        {repeatedData.map((item) => (
                            <div class="swiper-slide">
                                <div class="rv-33-gallary-item rv-34-portfolio-item">
                                    <img src={item.images} alt="Person" />
                                    <div class="rv-33-gallary-item__overlay">
                                        <a href="assets/img/Software/Portfolio/rv-34-portfolio-img1.jpg"
                                            class="rv-33-gallary-item__overlay-icon my-image-links" data-gall="gallery01">
                                            <i class="fa-solid fa-plus"></i>
                                        </a>
                                        <div class="rv-33-gallary-item__overlay-title">
                                            <span>{item.title}</span>
                                            <a href="project-details.html" class="rv-34-gallary-item__overlay-title">{item.heading}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                        {/* <div class="swiper-slide">
                <div class="rv-33-gallary-item rv-34-portfolio-item">
                    <img src={require('../assests/images/Software/Portfolio/rv-34-portfolio-img2.jpg')} alt="Person"/>
                    <div class="rv-33-gallary-item__overlay">
                        <a href="assets/img/Software/Portfolio/rv-34-portfolio-img2.jpg"
                            class="rv-33-gallary-item__overlay-icon  my-image-links" data-gall="gallery01">
                            <i class="fa-solid fa-plus"></i>
                        </a>
                        <div class="rv-33-gallary-item__overlay-title">
                            <span>Development</span>
                            <a href="project-details.html" class="rv-34-gallary-item__overlay-title">Improve IT
                                Security</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="swiper-slide">
                <div class="rv-33-gallary-item rv-34-portfolio-item">
                    <img src={require('../assests/images/Software/Portfolio/rv-34-portfolio-img3.jpg')} alt="Person"/>
                    <div class="rv-33-gallary-item__overlay">
                        <a href="assets/img/Software/Portfolio/rv-34-portfolio-img3.jpg"
                            class="rv-33-gallary-item__overlay-icon my-image-links" data-gall="gallery01">
                            <i class="fa-solid fa-plus"></i>
                        </a>
                        <div class="rv-33-gallary-item__overlay-title">
                            <span>Development</span>
                            <a href="project-details.html" class="rv-34-gallary-item__overlay-title">Improve IT
                                Security</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="swiper-slide">
                <div class="rv-33-gallary-item rv-34-portfolio-item">
                    <img src={require('../assests/images/Software/Portfolio/rv-34-portfolio-img4.jpg')} alt="Person"/>
                    <div class="rv-33-gallary-item__overlay">
                        <a href="assets/img/Software/Portfolio/rv-34-portfolio-img4.jpg"
                            class="rv-33-gallary-item__overlay-icon my-image-links" data-gall="gallery01">
                            <i class="fa-solid fa-plus"></i>
                        </a>
                        <div class="rv-33-gallary-item__overlay-title">
                            <span>Development</span>
                            <a href="project-details.html" class="rv-34-gallary-item__overlay-title">Improve IT
                                Security</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="swiper-slide">
                <div class="rv-33-gallary-item rv-34-portfolio-item">
                    <img src={require('../assests/images/Software/Portfolio/rv-34-portfolio-img1.jpg')} alt="Person"/>
                    <div class="rv-33-gallary-item__overlay">
                        <a href="assets/img/Software/Portfolio/rv-34-portfolio-img1.jpg"
                            class="rv-33-gallary-item__overlay-icon my-image-links" data-gall="gallery01">
                            <i class="fa-solid fa-plus"></i>
                        </a>
                        <div class="rv-33-gallary-item__overlay-title">
                            <span>Development</span>
                            <a href="project-details.html" class="rv-34-gallary-item__overlay-title">Improve IT
                                Security</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="swiper-slide">
                <div class="rv-33-gallary-item rv-34-portfolio-item">
                    <img src={require('../assests/images/Software/Portfolio/rv-34-portfolio-img2.jpg')} alt="Person"/>
                    <div class="rv-33-gallary-item__overlay">
                        <a href="assets/img/Software/Portfolio/rv-34-portfolio-img2.jpg"
                            class="rv-33-gallary-item__overlay-icon my-image-links" data-gall="gallery01">
                            <i class="fa-solid fa-plus"></i>
                        </a>
                        <div class="rv-33-gallary-item__overlay-title">
                            <span>Development</span>
                            <a href="project-details.html" class="rv-34-gallary-item__overlay-title">Improve IT
                                Security</a>
                        </div>
                    </div>
                </div>
            </div> */}
                    </div>
                </div>
            </section>

            {/* <!-- Portfolio section end  --> */}



            {/* <!-- Testimonial section start  --> */}

            <div class="rv-34-testimonial-sec ">
                <div class="container">
                    <div class="row">
                        <div class="rv-34-section-heading text-center mb-35">
                            <span class="rv-34-tag wow fadeInUp">Testimonials</span>
                            <h2 class="rv-34-section-title">
                                Customer Feedback <br /> <span>&</span> Comments.
                            </h2>
                        </div>

                        <div class="rv-34-testimonial-swiper-container swiper-container1">
                            <div class="swiper-wrapper">
                                {InnerData.testimonial.map((item)=>(
                                <div class="swiper-slide">
                                    <div class="rv-28-client-review">
                                        <div class="rv-28-testimonial-stars ">
                                            <img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star" />
                                            <img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star" />
                                            <img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star" />
                                            <img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star" />
                                            <img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star" />
                                        </div>
                                        <p>
                                            {item.comments}
                                        </p>
                                        <div class="rv-28-author">
                                            <h6>{item.name}</h6>
                                            <span>{item.subtitle}</span>
                                        </div>
                                        <img src={require('../assests/images/rv-colon.png')} class="rv-28-colon" alt="colon" />
                                    </div>
                                </div>
                                ))}
                                {/* <div class="swiper-slide">
                                    <div class="rv-28-client-review">
                                        <div class="rv-28-testimonial-stars">
                                            <img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star" />
                                            <img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star" />
                                            <img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star" />
                                            <img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star" />
                                            <img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star" />
                                        </div>
                                        <p>
                                            “Morbi consectetur elementum purus mattis cursus purus metus iaculis sagittis.
                                            Vestibulum
                                            molestie bibendum turpis
                                            luctus sem lacinia Quisque amet velit amet dui hendrerit ultricies id ipsum
                                            Mauris sit amet
                                            Suspends potent quis commodo
                                            ligula vestibulu lacinia est”
                                        </p>
                                        <div class="rv-28-author">
                                            <h6>Diansi Disouse</h6>
                                            <span>Happy Client</span>
                                        </div>
                                        <img src={require('../assests/images/rv-colon.png')} class="rv-28-colon" alt="colon" />
                                    </div>
                                </div>
                                <div class="swiper-slide">
                                    <div class="rv-28-client-review">
                                        <div class="rv-28-testimonial-stars">
                                            <img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star" />
                                            <img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star" />
                                            <img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star" />
                                            <img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star" />
                                            <img src={require('../assests/images/rv-27-testimoial-star.png')} alt="star" />
                                        </div>
                                        <p>
                                            “Morbi consectetur elementum purus mattis cursus purus metus iaculis sagittis.
                                            Vestibulum
                                            molestie bibendum turpis
                                            luctus sem lacinia Quisque amet velit amet dui hendrerit ultricies id ipsum
                                            Mauris sit amet
                                            Suspends potent quis commodo
                                            ligula vestibulu lacinia est”
                                        </p>
                                        <div class="rv-28-author">
                                            <h6>Dylan Meringue</h6>
                                            <span>Happy Customer</span>
                                        </div>
                                        <img src={require('../assests/images/rv-colon.png')} class="rv-28-colon" alt="colon" />
                                    </div>
                                </div> */}

                            </div>
                            <div class="rv-28-testimonial-controller swiper-pagination-bullets swiper-pagination-horizontal swiper-pagination">
                                {/* <button class="rv-28-cf-arrow-btn-left"><i class="fa-thin fa-arrow-left-long"></i></button>
                            <span class="rv-28-testimonial-count"></span>
                            <button class="rv-28-cf-arrow-btn-right"><i class="fa-thin fa-arrow-right-long"></i></button> */}
                            </div>
                        </div>





                    </div>

                    <div class="rv-28-clients">
                        <img src={require('../assests/images/rv-28-client1.jpg')} class="rv-28-client__1" alt="client" />
                        <img src={require('../assests/images/rv-28-client2.jpg')} class="rv-28-client__2" alt="client" />
                        <img src={require('../assests/images/rv-28-client3.jpg')} class="rv-28-client__3" alt="client" />
                        <img src={require('../assests/images/rv-28-client4.jpg')} class="rv-28-client__4" alt="client" />
                        <img src={require('../assests/images/rv-28-client5.jpg')} class="rv-28-client__5" alt="client" />
                        <img src={require('../assests/images/rv-28-client6.jpg')} class="rv-28-client__6" alt="client" />

                    </div>
                </div>
            </div>

            {/* <!-- Testimonial section end  -- */}


            {/* <!-- Team section start  --> */}

            <section class="rv-34-team mb-120">
                <div class="container">
                    <div class="row">
                        <div
                            class="rv-34-section-heading d-md-flex justify-content-md-between justify-content-center  align-items-center mb-30">
                            <div class="rv-34-section-heading-left">
                                <span class="rv-34-tag wow fadeInUp">Team Members</span>
                                <h2 class="rv-34-section-title wow fadeInUp" data-wow-delay="0.3s">Meet Our Leadership</h2>
                            </div>
                            <a href="team.html" class="rv-31-blog-item__content-readMore rv-34-view-link wow fadeInUp"
                                data-wow-delay="0.3s">View All Members
                                <FontAwesomeIcon icon={faArrowRightLong} />
                            </a>
                        </div>
                    </div>
                    <div class="row">
                        {teamMembers.slice(0,3).map((item) => (
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="rv-34-team-member">
                                <div class="rv-34-team-member__img ">
                                    <img src={item.image} class="w-100 " alt="team-member" />
                                </div>
                                <div class="rv-34-team-member__overlay">
                                    <div class="rv-34-team-member__info">
                                        <a href="#" class="d-block ">
                                            <h6 class="rv-34-team-member__info-name">{item.name}</h6>
                                        </a>
                                        <span class="rv-34-team-member__info-title">{item.title}</span>
                                    </div>
                                    <div class="rv-34-team-member__socials">
                                        <a href="#"><i class="fa-brands fa-facebook-f"></i></a>
                                        <a href="#"><i class="fa-brands fa-linkedin-in"></i></a>
                                        <a href="#"><i class="fa-brands fa-twitter"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        ))}
                        {/* <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="rv-34-team-member">
                                <div class="rv-34-team-member__img ">
                                    <img src={require('../assests/images/Software/team/rv-34-team2.jpg')} class="w-100 " alt="team-member" />
                                </div>
                                <div class="rv-34-team-member__overlay">
                                    <div class="rv-34-team-member__info">
                                        <a href="#" class="d-block ">
                                            <h6 class="rv-34-team-member__info-name">Ravi O'Leigh</h6>
                                        </a>
                                        <span class="rv-34-team-member__info-title">CHEF TECHNOLOGY</span>
                                    </div>
                                    <div class="rv-34-team-member__socials">
                                        <a href="#"><i class="fa-brands fa-facebook-f"></i></a>
                                        <a href="#"><i class="fa-brands fa-linkedin-in"></i></a>
                                        <a href="#"><i class="fa-brands fa-twitter"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="rv-34-team-member">
                                <div class="rv-34-team-member__img ">
                                    <img src={require('../assests/images/Software/team/rv-34-team3.jpg')} class="w-100 " alt="team-member" />
                                </div>
                                <div class="rv-34-team-member__overlay">
                                    <div class="rv-34-team-member__info">
                                        <a href="#" class="d-block ">
                                            <h6 class="rv-34-team-member__info-name">Ravi O'Leigh</h6>
                                        </a>
                                        <span class="rv-34-team-member__info-title">CHEF TECHNOLOGY</span>
                                    </div>
                                    <div class="rv-34-team-member__socials">
                                        <a href="#"><i class="fa-brands fa-facebook-f"></i></a>
                                        <a href="#"><i class="fa-brands fa-linkedin-in"></i></a>
                                        <a href="#"><i class="fa-brands fa-twitter"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>

            {/* <!-- Team section end  --> */}


            {/* <!-- Blog section start  --> */}

            <section class="rv-34-blog pt-120 pb-90">
                <div class="container">
                    <div class="row">
                        <div class="rv-34-section-heading text-center mb-35">
                            <span class="rv-34-tag wow fadeInUp">Blog & News</span>
                            <h2 class="rv-34-section-title wow fadeInUp" data-wow-delay="0.3s">Our Latest Findings</h2>
                        </div>
                    </div>
                    <div class="rv-34-blog-slider swiper-container">
                        <div class="swiper-wrapper">
                            {InnerData.blog.map((item) => (
                            <div class="swiper-slide">
                                <div class="rv-34-blog-item card">
                                    <div class="rv-34-blog-item__img">
                                        <a href="blog-details.html">
                                            <img src={item.images} class="w-100 " alt="blog page" />
                                        </a>
                                    </div>
                                    <div class="rv-34-blog-item__content">
                                        <a href="blog-details.html" class="rv-34-blog-item__content-title">
                                            {item.heading}
                                        </a>
                                        <hr />
                                        <div class="rv-34-blog-item-tags">
                                            <div class=" rv-34-blog-item-date_comments m-0 ">
                                                <i class="fa-light fa-calendar-days"></i>
                                                <span class="me-3 ">{item.date}</span><br />
                                                <i class="fa-sharp fa-regular fa-comments"></i>
                                                <span class="me-1 ">{item.comment}</span>
                                            </div>
                                            <div class="rv-34-blog-share-btn">
                                                <FontAwesomeIcon icon={faShareNodes} />

                                                <div class="rv-34-blog-share-btn-container">
                                                    <a href="#" class="rv-34-blog-share-btn-container__item"><i
                                                        class="fa-brands fa-facebook-f"></i></a>
                                                    <a href="#" class="rv-34-blog-share-btn-container__item"><i
                                                        class="fa-brands fa-twitter"></i></a>
                                                    <a href="#" class="rv-34-blog-share-btn-container__item"><i
                                                        class="fa-brands fa-instagram"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <a href="blog-details.html" class="rv-34-btn">{item.title}</a>
                                </div>
                            </div>
                            ))}
                            {/* <div class="swiper-slide">
                                <div class="rv-34-blog-item card">
                                    <div class="rv-34-blog-item__img">
                                        <a href="blog-details.html">
                                            <img src={require('../assests/images/Software/blog/rv-34-blog2.jpg')} class="w-100 "
                                                alt="blog page" />
                                        </a>
                                    </div>
                                    <div class="rv-34-blog-item__content">
                                        <a href="blog-details.html" class="rv-34-blog-item__content-title">
                                            Foremost 14 Tech Stacks in<br />
                                            Software Building.
                                        </a>
                                        <hr />
                                        <div class="rv-34-blog-item-tags">
                                            <div class=" rv-34-blog-item-date_comments m-0 ">
                                                <i class="fa-light fa-calendar-days"></i>
                                                <span class="me-3 ">March 16, 2023</span><br />
                                                <i class="fa-sharp fa-regular fa-comments"></i>
                                                <span class="me-1 ">2 Comments</span>
                                            </div>
                                            <div class="rv-34-blog-share-btn">
                                                <FontAwesomeIcon icon={faShareNodes} />

                                                <div class="rv-34-blog-share-btn-container">
                                                    <a href="#" class="rv-34-blog-share-btn-container__item"><i
                                                        class="fa-brands fa-facebook-f"></i></a>
                                                    <a href="#" class="rv-34-blog-share-btn-container__item"><i
                                                        class="fa-brands fa-twitter"></i></a>
                                                    <a href="#" class="rv-34-blog-share-btn-container__item"><i
                                                        class="fa-brands fa-instagram"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <a href="blog-details.html" class="rv-34-btn">Meeting</a>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="rv-34-blog-item card">
                                    <div class="rv-34-blog-item__img">
                                        <a href="blog-details.html">
                                            <img src={require('../assests/images/Software/blog/rv-34-blog3.jpg')} class="w-100 "
                                                alt="blog page" />
                                        </a>
                                    </div>
                                    <div class="rv-34-blog-item__content">
                                        <a href="blog-details.html" class="rv-34-blog-item__content-title">
                                            Foremost 14 Tech Stacks in<br />
                                            Software Building.
                                        </a>
                                        <hr />
                                        <div class="rv-34-blog-item-tags">
                                            <div class=" rv-34-blog-item-date_comments m-0 ">
                                                <i class="fa-light fa-calendar-days"></i>
                                                <span class="me-3 ">March 16, 2023</span><br />
                                                <i class="fa-sharp fa-regular fa-comments"></i>
                                                <span class="me-1 ">2 Comments</span>
                                            </div>
                                            <div class="rv-34-blog-share-btn">
                                                <FontAwesomeIcon icon={faShareNodes} />
                                                <div class="rv-34-blog-share-btn-container">
                                                    <a href="#" class="rv-34-blog-share-btn-container__item"><i
                                                        class="fa-brands fa-facebook-f"></i></a>
                                                    <a href="#" class="rv-34-blog-share-btn-container__item"><i
                                                        class="fa-brands fa-twitter"></i></a>
                                                    <a href="#" class="rv-34-blog-share-btn-container__item"><i
                                                        class="fa-brands fa-instagram"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <a href="blog-details.html" class="rv-34-btn">Optimizing</a>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="rv-34-blog-item card">
                                    <div class="rv-34-blog-item__img">
                                        <a href="blog-details.html">
                                            <img src={require('../assests/images/Software/blog/rv-34-blog2.jpg')} class="w-100 "
                                                alt="blog page" />
                                        </a>
                                    </div>
                                    <div class="rv-34-blog-item__content">
                                        <a href="blog-details.html" class="rv-34-blog-item__content-title">
                                            Foremost 14 Tech Stacks in<br />
                                            Software Building.
                                        </a>
                                        <hr />
                                        <div class="rv-34-blog-item-tags">
                                            <div class=" rv-34-blog-item-date_comments m-0 ">
                                                <i class="fa-light fa-calendar-days"></i>
                                                <span class="me-3 ">March 16, 2023</span><br />
                                                <i class="fa-sharp fa-regular fa-comments"></i>
                                                <span class="me-1 ">2 Comments</span>
                                            </div>
                                            <div class="rv-34-blog-share-btn">
                                                <FontAwesomeIcon icon={faShareNodes} />

                                                <div class="rv-34-blog-share-btn-container">
                                                    <a href="#" class="rv-34-blog-share-btn-container__item"><i
                                                        class="fa-brands fa-facebook-f"></i></a>
                                                    <a href="#" class="rv-34-blog-share-btn-container__item"><i
                                                        class="fa-brands fa-twitter"></i></a>
                                                    <a href="#" class="rv-34-blog-share-btn-container__item"><i
                                                        class="fa-brands fa-instagram"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <a href="blog-details.html" class="rv-34-btn">Meeting</a>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="rv-34-blog-item card">
                                    <div class="rv-34-blog-item__img">
                                        <a href="blog-details.html">
                                            <img src={require('../assests/images/Software/blog/rv-34-blog3.jpg')} class="w-100 "
                                                alt="blog page" />
                                        </a>
                                    </div>
                                    <div class="rv-34-blog-item__content">
                                        <a href="blog-details.html" class="rv-34-blog-item__content-title">
                                            Foremost 14 Tech Stacks in<br />
                                            Software Building.
                                        </a>
                                        <hr />
                                        <div class="rv-34-blog-item-tags">
                                            <div class=" rv-34-blog-item-date_comments m-0 ">
                                                <i class="fa-light fa-calendar-days"></i>
                                                <span class="me-3 ">March 16, 2023</span><br />
                                                <i class="fa-sharp fa-regular fa-comments"></i>
                                                <span class="me-1 ">2 Comments</span>
                                            </div>
                                            <div class="rv-34-blog-share-btn">
                                                <FontAwesomeIcon icon={faShareNodes} />

                                                <div class="rv-34-blog-share-btn-container">
                                                    <a href="#" class="rv-34-blog-share-btn-container__item"><i
                                                        class="fa-brands fa-facebook-f"></i></a>
                                                    <a href="#" class="rv-34-blog-share-btn-container__item"><i
                                                        class="fa-brands fa-twitter"></i></a>
                                                    <a href="#" class="rv-34-blog-share-btn-container__item"><i
                                                        class="fa-brands fa-instagram"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <a href="blog-details.html" class="rv-34-btn">Optimizing</a>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="rv-34-blog-item card">
                                    <div class="rv-34-blog-item__img">
                                        <a href="blog-details.html">
                                            <img src={require('../assests/images/Software/blog/rv-34-blog1.jpg')} class="w-100 "
                                                alt="blog page" />
                                        </a>
                                    </div>
                                    <div class="rv-34-blog-item__content">
                                        <a href="blog-details.html" class="rv-34-blog-item__content-title">
                                            Foremost 14 Tech Stacks in<br />
                                            Software Building.
                                        </a>
                                        <hr />
                                        <div class="rv-34-blog-item-tags">
                                            <div class=" rv-34-blog-item-date_comments m-0 ">
                                                <i class="fa-light fa-calendar-days"></i>
                                                <span class="me-3 ">March 16, 2023</span><br />
                                                <i class="fa-sharp fa-regular fa-comments"></i>
                                                <span class="me-1 ">2 Comments</span>
                                            </div>
                                            <div class="rv-34-blog-share-btn">
                                                <FontAwesomeIcon icon={faShareNodes} />

                                                <div class="rv-34-blog-share-btn-container">
                                                    <a href="#" class="rv-34-blog-share-btn-container__item"><i
                                                        class="fa-brands fa-facebook-f"></i></a>
                                                    <a href="#" class="rv-34-blog-share-btn-container__item"><i
                                                        class="fa-brands fa-twitter"></i></a>
                                                    <a href="#" class="rv-34-blog-share-btn-container__item"><i
                                                        class="fa-brands fa-instagram"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <a href="blog-details.html" class="rv-34-btn">Development</a>
                                </div>
                            </div> */}

                        </div>
                        <div class="rv-34-blog-controller mt-45 swiper-pagination-bullets swiper-pagination-horizontal swiper-pagination"></div>
                    </div>
                </div>
            </section>

            {/* <!-- Blog section end  --> */}


            {/* <!-- Footer section start  --> */}

            <FooterInner />

            {/* <!-- Footer section end  --> */}




        </div>




    )
}

export default Home